import * as React from "react";
import PropTypes from "prop-types";
import { Tabs, Tab, Typography, Box, ThemeProvider } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", marginTop: "5px" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          bgcolor: "secondary.main",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="About Us" {...a11yProps(0)} />
          <Tab label="Contact Us" {...a11yProps(1)} />
          <Tab label="Training Catalog" {...a11yProps(2)} />
          <Tab label="Blog" {...a11yProps(3)} />
          <Tab label="Client Login" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Typography variant="h2" align="left" gutterBottom>
          About Us
        </Typography>
        <Typography variant="h4" align="left">
          WHY WE EXCEL AT PROCEDENCE
        </Typography>
        <Typography variant="body1" align="left" gutterBottom paragraph>
          Procedence improves your bottom line and competitive edge by helping
          your people adapt to new technology. That technology may be software,
          hardware or even new practices that change the way your company
          fundamentally operates. Our learning and performance specialists help
          companies, big and small, discover how to get the best possible return
          on their technology and personnel investments to differentiate
          themselves from the competition. We do this by assessing current
          workflow process, making technology-based recommendations and
          providing post evaluation of progress and usage of solutions. Not only
          do our findings, recommendations and follow-up propel our clients’
          business, it helps us grow, adapt and constantly provide more
          efficient solutions.
        </Typography>
        <Typography variant="h4" align="left">
          WHY WE DO WHAT WE DO
        </Typography>
        <Typography variant="body1" align="left">
          As a nation, our competitive edge has always been the ability to adapt
          to new technology. This same virtue must remain to maintain our global
          leadership position. At Procedence, it is our mission to help ensure
          that continuation happens. We constantly submerge ourselves in the
          vast process vs. production issues that affect our clients. Plus, our
          approach to learning how to adapt to new technology is non-threatening
          and fun, yet practical, and yes, extremely productive.
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Typography variant="h2" align="left" gutterBottom>
          Contact Us
        </Typography>
        <Typography variant="body1" align="left" gutterBottom>
          Customer feedback is the lifeblood of our business. Tell us what’s on
          your mind, good or bad.
        </Typography>
        <Typography variant="body1" align="left" gutterBottom>
          We respond to all customer feedback and look forward to hearing from
          you.
        </Typography>
        <Typography variant="body1" align="left" gutterBottom>
          <a href="mailto:contact@procedence.com">Contact Procedence</a>
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Typography variant="h2" align="left" gutterBottom>
          Training Catalog
        </Typography>
        <Typography variant="h5" align="left">
          <a href="http://www.procedence.com/catalog/mos/">Microsoft Office</a>
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Typography variant="h5" align="left">
          <a href="http://www.procedence.com/category/language/english/">
            Read our blog here!
          </a>
        </Typography>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Typography variant="body1" align="left">
          Login Link Coming Soon
        </Typography>
      </TabPanel>
    </Box>
  );
}
