import logo from "./logo.svg";
import TopNav from "./Sections/TopNav";
import {
  Paper,
  createTheme,
  colors,
  ThemeProvider,
  Grid,
  Typography,
} from "@mui/material";
import { Image } from "mui-image";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./App.css";

const theme = createTheme({
  palette: {
    secondary: {
      main: colors.blue[500],
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Image
              src="https://procedencebucket2023.s3.amazonaws.com/procedencebannerv2.jpg"
              duration={2000}
            ></Image>
          </Grid>
        </Grid>
        <TopNav />
      </div>
    </ThemeProvider>
  );
}

export default App;
